<template>
    <div>
        <v-sheet class="px-6 pt-6">
            <div class="container">
                <div class="d-flex flex-wrap justify-space-between mb-8">
                    <v-avatar size="120" class="mr-6">
                        <img
                            class=""
                            src="/img/database.c82dbe43.svg"
                            alt=""
                        />
                    </v-avatar>
                    <div class="flex-1">
                        <div class="d-flex align-start justify-space-between">
                            <div class="mb-3">
                                <h5 class="mr-2 mb-0">
                                    {{ title }}                                   
                                </h5>
                                <span></span>                                
                                <p class="text--disabled mb-0">{{ description }}</p>
                            </div>
                            <div>
                                
                            </div>
                        </div>
                        <div class="d-flex mb-2">
                            <p class="ma-0 mr-8">
                                <span class="font-weight-medium">{{ code }}</span
                                >
                                <!-- <span class="body-2 text--disabled"
                                    >Posts</span
                                > -->
                            </p>
                        </div>
                        <p class="text--disabled">
                            {{ contact_name }}
                        </p>
                    </div>
                    <div class="flex-1 text-right">
                        <v-btn class="ma-2" dark color="success" :loading="loading" @click="save">
                            <v-icon class="pr-2">mdi-content-save</v-icon>
                            Save
                        </v-btn>
                    </div>
                </div>
            </div>
        </v-sheet>
        <v-row justify="center">     
            <!-- <v-form  v-model="editFormValid" ref="editForm">        -->
            <v-col cols="10" md="5">
                <e-info-card
                    title="Primary Fields"
                    sub-title=""
                >
                    <template slot="basic-info-slot">     
                        <v-form  v-model="editFormValid" ref="editForm">
                            <v-list>
                                <v-list-item>
                                    <!-- <v-list-item-avatar>
                                        <img :src="''" alt="" />
                                    </v-list-item-avatar> -->                                                                                                                              
                                    <v-list-item-content>
                                        <v-text-field 
                                            label="Code" 
                                            v-model="code"
                                            outlined
                                            :readonly="!createMode"   
                                            :validate-on-blur="createMode"
                                            :rules="(createMode ? codeRules : [])"                                       
                                        />
                                    </v-list-item-content>                                
                                </v-list-item>
                                <v-list-item>                                                              
                                    <v-list-item-content>
                                        <v-text-field 
                                            label="Title" 
                                            v-model="title"
                                            :rules="firstNameRules"
                                            :validate-on-blur="createMode"
                                        />
                                    </v-list-item-content>                                
                                </v-list-item>
                                <v-list-item>                                                             
                                    <v-list-item-content>
                                        <v-text-field 
                                            label="Description" 
                                            v-model="description"
                                            :rules="firstNameRules"
                                            :validate-on-blur="createMode"
                                        />
                                    </v-list-item-content>                                
                                </v-list-item>
                            </v-list>  
                        </v-form>                      
                    </template>
                </e-info-card>
            </v-col>
            <v-col cols="10" md="5">
                <e-info-card
                    title="Contact Person"
                    sub-title=""
                >
                    <template slot="basic-info-slot">                        
                            <v-list>
                                <v-list-item>
                                    <!-- <v-list-item-avatar>
                                        <img :src="''" alt="" />
                                    </v-list-item-avatar> -->                                                                                                                       
                                    <v-list-item-content>
                                        <v-text-field 
                                            label="Name" 
                                            v-model="contact_name"
                                        />
                                    </v-list-item-content>                                
                                </v-list-item>
                                <v-list-item>                                                             
                                    <v-list-item-content>
                                        <v-text-field 
                                            label="Email" 
                                            v-model="contact_email"
                                        />
                                    </v-list-item-content>                                
                                </v-list-item>
                                <v-list-item>                                                             
                                    <v-list-item-content>
                                        <v-text-field 
                                            label="Phone" 
                                            v-model="contact_phoneno"
                                        />
                                    </v-list-item-content>                                
                                </v-list-item>
                            </v-list>                        
                    </template>
                </e-info-card>
            </v-col>
            <!-- </v-form> -->
            <v-col cols="10" md="5">
                <e-info-card
                    title="Associated Users"                    
                >
                    <template slot="basic-info-slot">
                        <!-- <v-list>                            
                            <v-list-item>                                
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <span >User Code</span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ crud_code }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>                                
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <span >Invitation Code</span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ invitation_code }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>                                
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <span >Invitation Created On</span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ invitation_created_date }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>                                
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <span >Activation Link</span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        Click the button to visit the activation link
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn class="ma-2" dark color="primary" small :href="'/auth/activation/' + activation_code " target="_blank">
                                        Visit
                                    </v-btn>
                                </v-list-item-action>                                
                            </v-list-item>
                            <v-list-item>                                
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <span >Activation Date</span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ activation_date }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list> -->
                    </template>
                </e-info-card>
            </v-col>
        </v-row>
        <!-- <base-card class="transparent">
            
        </base-card> -->
        <v-snackbar
            v-model="snackbar"
            :timeout="4000"
            top
            tile
            :color="snackbarColor + ' -accent-2'"            
            >
            {{ snackbarMessage }}
        </v-snackbar>
    </div>
</template>
<script>
import avatarGroupCard from '@/components/card/AvatarGroupCard';
import EInfoCard from '@/components/card/BasicInfoCard';
import api from "src/api/index";
import { fieldNotEmpty, fieldMinLength } from "src/core/vuetify-validators.js"

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Brand Edit'
    },
    components: {
        avatarGroupCard,
        EInfoCard
    },
    data() {
        return {
            //primary data
            code: '',
            title: '',
            description: '',
            contact_name: '',
            contact_email: '',
            contact_phoneno: '',
            crud_code: '',
            disabled: false,
            //ui mechanics      
            createMode: false,                  
            invitationFormValid: true,
            editFormValid: true,
            brand_to_add: '',
            //error
            loading: false,
            snackbar: false,
            snackbarColor: 'green',
            snackbarMessage: '',
            error: '',
            //reference data
            brand_list_items: [],                                    
            roles_list_items: [
                        {
                            selectvalue: "admin",
                            selecttext: "Admin",
                        },
                        {
                            selectvalue: "viewer",
                            selecttext: "Viewer",
                        },
                        {
                            selectvalue: "designer",
                            selecttext: "Designer",
                        },
                        {
                            selectvalue: "dev",
                            selecttext: "Developer",
                        },
                        {
                            selectvalue: "sysadmin",
                            selecttext: "System Admin",
                        }
                    ],
            firstNameRules: [
                fieldNotEmpty,            
                (v) => fieldMinLength(v, 2)   
            ],
            codeRules: [
                fieldNotEmpty,            
                (v) => fieldMinLength(v, 2)  
            ]
        }
    },
    computed: {
        selected_brands() {     
            if(this.brand && this.brand_list_items) {
                const rslt = this.brand_list_items.filter(i=> this.brand.includes(i.code));                
                if(rslt) {
                    return rslt;
                }                                           
            }                   
            return [];
        },
        selected_brands_descr() {     
            if(this.brand && this.brand_list_items) {
                const rslt = this.brand_list_items.filter(i=> this.brand.includes(i.code));                
                if(rslt) {
                    return rslt.map(i=>i.title).join(', ');
                }                                           
            }                   
            return "";
        },
        status() {
            return (this.disabled) ? "Disabled" :
                    (this.invitation_code) ? "Invited" :
                    (this.activation_date === "" || this.activation_date == null) ? "Awaiting Activation" : "Active";                    
        }
    },
    created() {
        if(this.$route.params.code) {
            this.crud_code = this.$route.params.code;       
            this.createMode = false;     
        }
        else {            
            this.createMode = true;
        }
        
    },
    async mounted() {
        // const rsp = await api.brand.getAll();            
        // this.brand_list_items = rsp.data.map(i=> {return {...i, selectvalue: i.code, selecttext: i.title}});
        const rspB = await api.brand.getOne(this.crud_code);
        if(rspB && rspB.data) {
            this.code = rspB.data.code;
            this.title = rspB.data.title;
            this.description = rspB.data.description;
            this.contact_name = rspB.data.contact_name;
            this.contact_email = rspB.data.contact_email;
            this.contact_phoneno = rspB.data.contact_phoneno;            
        }
        //this.editFormValid = true;
    },
    methods: {
        // addSelectedBrand() {
        //     if(!this.brand) {
        //         this.brand = [];
        //     }
        //     if(!this.brand.includes(this.brand_to_add)){
        //         this.brand.push(this.brand_to_add);                
        //     }
        //     this.brand_to_add = "";            
        // },
        // removeBrand(b){
        //     for(var i = 0; i < this.brand.length; i++){             
        //         if ( this.brand[i] === b) {             
        //             this.brand.splice(i, 1); 
        //         }            
        //     }
        // },
        save() {
            this.error = '';     
            this.$refs.editForm.validate();                          
            if(!this.editFormValid) {                
                this.snackbarColor = "red";
                this.snackbarMessage = "There are invalid fields.";
                this.snackbar = true;
                return;
            }                                
            this.loading = true;   
            if(this.createMode) {
                api.brand.create({      
                    code: this.code,                          
                    title: this.title,
                    description: this.description,
                    contact_name: this.contact_name,
                    contact_email: this.contact_email,
                    contact_phoneno: this.contact_phoneno,                
                })
                .then((rsp)=>{               
                    //this.$router.push("/mng/system/users/edit/" + rsp.data.code);  
                    //this.$router.push({ name: "system-users-edit", params: {code: rsp.data.code}});
                    // this.snackbarColor = "green";
                    // this.snackbarMessage = "User updated successfully";
                    // this.snackbar = true;  
                    this.$router.push("/mng/adpoints/brands/");
                })
                .catch((error)=>{
                    //this.error = error.response.data.message;
                    this.snackbarColor = "red";
                    this.snackbarMessage = error.response.data.message;
                    this.snackbar = true;
                })
                .finally(()=>{
                    this.loading = false;
                })
            }  
            else {
                api.brand.update(this.crud_code, {                                
                    title: this.title,
                    description: this.description,
                    contact_name: this.contact_name,
                    contact_email: this.contact_email,
                    contact_phoneno: this.contact_phoneno,                
                })
                .then((rsp)=>{               
                    //this.$router.push("/mng/system/users/edit/" + rsp.data.code);  
                    //this.$router.push({ name: "system-users-edit", params: {code: rsp.data.code}});
                    // this.snackbarColor = "green";
                    // this.snackbarMessage = "User updated successfully";
                    // this.snackbar = true;  
                    this.$router.push("/mng/adpoints/brands/");
                })
                .catch((error)=>{
                    //this.error = error.response.data.message;
                    this.snackbarColor = "red";
                    this.snackbarMessage = error.response.data.message;
                    this.snackbar = true;
                })
                .finally(()=>{
                    this.loading = false;
                })
            }                 
        },       
    }
}
</script>
<style lang="scss">
.ul-widget-profile-img {
    position: relative;
}
.ul-widget-profile-img:after {
    // content: "";
    // position: absolute;
    // top: 44px;
    // left: 50%;
    // width: 1px;
    // height: calc(100% - 30px);
    // background: #B3C0CE;
    content: '';
    position: absolute;
    top: 44px;
    left: 50%;
    width: 1px;
    height: calc(100% - 30px);
    background: #b3c0ce;
}
</style>
